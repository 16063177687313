import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { RootState } from '../rootReducer';

export const getUsersState = (state: RootState) => state.users || initialState;

export const usersList = createSelector(
  getUsersState,
  (state) => state.usersList,
);

export const usersListLoading = createSelector(
  getUsersState,
  (state) => state.isLoading,
);

export const usersListError = createSelector(
  getUsersState,
  (state) => state.errors.usersList,
);

export const activeUser = createSelector(
  getUsersState,
  (state) => state.activeUser.user,
);

export const activeUserLoading = createSelector(
  getUsersState,
  (state) => state.activeUser.isLoading,
);

export const activeUserError = createSelector(
  getUsersState,
  (state) => state.errors.activeUser,
);
