import { routes } from 'routes/routes';

export enum userRole {
  Admin = 1,
  User,
  FinanceManager,
  CustomerSupport,
  Sales,
  OperationsExecutive,
  AccountManager,
  MarketplaceManager,
  Tech,
  SuperAdmin = 13,
}

export const getRules = (
  navPermissionMap: Record<string, boolean>,
): Record<string, boolean> => {
  return {
    INDEX: navPermissionMap[routes.index],
    ORDERS_LANDING: navPermissionMap[routes.ordersLanding],
    BRANDS_LANDING: navPermissionMap[routes.bambooBrandsLanding],
    CLIENTS_LANDING: navPermissionMap[routes.clientsLanding],
    SIGN_IN_LANDING: navPermissionMap[routes.signInLanding],
    CATALOGS_LANDING: navPermissionMap[routes.bambooCatalogsLanding],
    PRODUCTS_LANDING: navPermissionMap[routes.productsLanding],
    SHOPPING_CARTS_LANDING: navPermissionMap[routes.shoppingCartsLanding],
    BRAND_INFORMATION: navPermissionMap[routes.brandInformation],
    SUPPLIERS_LANDING: navPermissionMap[routes.suppliersLanding],
    CLIENT_INFORMATION: navPermissionMap[routes.clientInformation],
    CATALOG_INFORMATION: navPermissionMap[routes.catalogInformation],
    SUPPLIER_INFORMATION: navPermissionMap[routes.supplierInformation],
    PAGE_NOT_FOUND: navPermissionMap[routes.pageNotFound],
    RESET_PASSWORD_LANDING: navPermissionMap[routes.resetPasswordLanding],
    ORDER_INFORMATION_LANDING: navPermissionMap[routes.orderInformationLanding],
    CREATE_NEW_PASSWORD_LANDING:
      navPermissionMap[routes.createNewPasswordLanding],
    RESET_PASSWORD_LINK_EXPIRED_LANDING:
      navPermissionMap[routes.resetPasswordLinkExpiredLanding],
    BRAND_PRODUCTS: navPermissionMap[routes.brandProducts],
    CLIENT_CATALOG: navPermissionMap[routes.clientCatalog],
    CATALOG_CLIENT: navPermissionMap[routes.catalogsClient],
    SHOPPING_CARTS_INFORMATION:
      navPermissionMap[routes.shoppingCartsInformation],
    INVOICES: navPermissionMap[routes.invoices],
    FUNDINGS: navPermissionMap[routes.funding],
    INVENTORY_INFORMATION: navPermissionMap[routes.inventory],
    USERS_LANDING: navPermissionMap[routes.usersLanding],
    USER_DETAILS: navPermissionMap[routes.userDetail],
    USER_INFORMATION: navPermissionMap[routes.usersInformation],
    FINANCE_OPERATION: navPermissionMap[routes.financeOperation],
    MAINTENANCE: navPermissionMap[routes.maintenance],
    ENEBA: navPermissionMap[routes.eneba],
    RECONCILIATION: navPermissionMap[routes.reconciliation],
    CLIENT_HOME: navPermissionMap[routes.index],
    GAMIVO: navPermissionMap[routes.gamivo],
    INVENTORY_REPORTS: navPermissionMap[routes.inventoryReports],
    INVENTORY_REPORT_INFORMATION:
      navPermissionMap[routes.inventoryReportInformation],
    PRODUCT_RULES: navPermissionMap[routes.productRulesLanding],
    CLIENT_REPORT: navPermissionMap[routes.clientReport],
    SUPPLIER_REPORT: navPermissionMap[routes.supplierReport],
    BRAND_REPORT: navPermissionMap[routes.brandReport],
    RESTORE_CATALOG: navPermissionMap[routes.restoreCatalog],
    CARDS_LANDING: navPermissionMap[routes.cardsLanding],
    INVENTORY_INVOICES: navPermissionMap[routes.inventoryInvoices],
    CATEGORIES_LANDING: navPermissionMap[routes.categoriesLanding],
    ENEBA_AUTO_MAP: navPermissionMap[routes.enebaAutoMap],
    BINANCE_REQUESTS: navPermissionMap[routes.binanceRequests],
    SETINGS: navPermissionMap[routes.settings],
    G2A: navPermissionMap[routes.g2a],
    INVENTORY_STATISTICS: navPermissionMap[routes.inventoryStatistics],
    INVENTORY_CARDS: navPermissionMap[routes.inventoryCards],
    KINGUIN: navPermissionMap[routes.kinguin],
    RECONCILIATION_PROCESS: navPermissionMap[routes.reconciliationProcess],
    BAMBOO_BRANDS_LANDING: navPermissionMap[routes.bambooBrandsLanding],
    BAMBOO_CATALOGS_LANDING: navPermissionMap[routes.bambooCatalogsLanding],
    BAMBOO_CATALOG_INFORMATION:
      navPermissionMap[routes.bambooCatalogsInformation],
    SUPPLIER_ORDER: navPermissionMap[routes.supplierOrder],
    REPORT_GENERATION: navPermissionMap[routes.reportGeneration],
    BUFFER_HISTORY: navPermissionMap[routes.bufferHistory],
    FAQ: navPermissionMap[routes.faqs],
  };
};
