import { USER_PERMISSIONS } from 'config/RBAC';
import { checkPermissions } from 'utils/checkPermissions';
import { useSelector } from 'react-redux';
import { activeUser } from 'store/users/selectors';

export function usePermissionAccess(permission: USER_PERMISSIONS) {
  const user = useSelector(activeUser);

  if (permission && user?.navPermissions) {
    return checkPermissions(permission, user?.navPermissions);
  }

  return false;
}
