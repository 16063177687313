import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { UsersListResponse } from 'api/users/Models';
import { Arguments, fetchUsersList } from 'api/users/getUsersList';
import { User, UserStatuses } from 'entries/users';
import { getMyDetails } from 'api/users/getMyDetails';
import {
  convertRoutePermissions,
  getActionPermissions,
  getNavPermissions,
} from 'utils/convertRoutePermissions';

type AsyncThunkOptions = {
  rejectValue: number;
};

export const getActiveUser = createAsyncThunk<User, void, AsyncThunkOptions>(
  'users/getActiveUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getMyDetails();

      const convertedPermissions = convertRoutePermissions(
        response.routePermissions,
      );

      const transformedResponse: User = {
        ...response,
        routePermissions: convertedPermissions,
        navPermissions: getNavPermissions(convertedPermissions),
        actionPermissions: getActionPermissions(convertedPermissions),
      };

      return transformedResponse;
    } catch (error) {
      return rejectWithValue(error.response.status);
    }
  },
);

export const getUsersList = createAsyncThunk<
  UsersListResponse,
  Arguments,
  AsyncThunkOptions
>('users/getUsersList', async (queryParams, { rejectWithValue }) => {
  try {
    return await fetchUsersList(queryParams);
  } catch (error) {
    return rejectWithValue(error.response.status);
  }
});

export const deleteUser = createAction<string | number>('users/deleteUser');
export const addNewUserToList = createAction<User>('users/addNewUserToList');
export const changeUserStatus = createAction<{
  id: string | number;
  status: UserStatuses;
}>('users/changeUserStatus');

export const updateUserInList = createAction<User>('users/updateUserInList');
export const updateCurrentUser = createAction<User>('users/updateCurrentUser');
export const removeCurrentUser = createAction('users/removeCurrentUser');
export const clearUserErrors = createAction('users/clearUserErrors');
