import { USER_PERMISSIONS } from 'config/RBAC';
import { getRules } from 'config/RBAC/RBAC';

export const checkPermissions = (
  action: USER_PERMISSIONS,
  navPermissionMap: Record<string, boolean>,
): boolean => {
  if (!action) {
    return false;
  }

  const permissionMap = getRules(navPermissionMap);

  return permissionMap[action] ?? false;
};
